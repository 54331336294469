import {
  ITransferAccountConfirmPayload,
  ITransferAccountPayload,
  IUpdateAccountParams
} from '@/modules/account/account.interface';
import { IAccountApi } from '@connect-saas/types';

import { IAddApiKey, IApiKey, IApiKeyResponse } from '@/modules/api-key/api-key.interface';
import {
  IAvailablePlanApi,
  IBillingDetails,
  IChangeTierPlan,
  IHostedPage
} from '@/modules/billing/billing.interface';
import ClientInterface from './common/ClientInterface';
import ResourceResponse from './common/ResourceResponse';

export default class Account {
  private readonly client: ClientInterface;

  private readonly resource: string = 'account';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getAccount(): Promise<ResourceResponse<IAccountApi>> {
    return this.client.get(this.resource);
  }

  updateAccount(body: IUpdateAccountParams): Promise<ResourceResponse<IAccountApi>> {
    return this.client.patch(this.resource, body);
  }

  deleteAccount(): Promise<ResourceResponse<IAccountApi>> {
    return this.client.post(`${this.resource}/delete`);
  }

  getBillingDetails(): Promise<ResourceResponse<IBillingDetails>> {
    return this.client.get(`${this.resource}/billing`);
  }

  changeTierPlan(body: IChangeTierPlan): Promise<ResourceResponse<IHostedPage>> {
    return this.client.post(`${this.resource}/subscription/change-tier`, body);
  }

  downgradePlan(body: IChangeTierPlan): Promise<ResourceResponse<IHostedPage>> {
    return this.client.post(`${this.resource}/subscription/downgrade`, body);
  }

  getUserPortalSession(): Promise<void> {
    return this.client.get(`${this.resource}/billing/portal`);
  }

  addPaymentMethod(): Promise<ResourceResponse<IHostedPage>> {
    return this.client.get(`${this.resource}/billing/add-payment-method`);
  }

  getAvailablePlan(): Promise<ResourceResponse<IAvailablePlanApi>> {
    return this.client.get(`${this.resource}/billing/plans`);
  }

  cancelSubscription(): Promise<void> {
    return this.client.get(`${this.resource}/subscription/cancel`);
  }

  transferAccount(payload: ITransferAccountPayload): Promise<ResourceResponse<void>> {
    return this.client.post(`${this.resource}/transfer`, payload);
  }

  transferAccountConfirmed(
    payload: ITransferAccountConfirmPayload
  ): Promise<ResourceResponse<void>> {
    return this.client.post(`${this.resource}/transfer-confirm`, payload);
  }

  getApiKey(): Promise<IApiKeyResponse> {
    return this.client.get(`${this.resource}/api-key`);
  }

  createApiKey(payload: IAddApiKey): Promise<ResourceResponse<IApiKey>> {
    return this.client.post(`${this.resource}/api-key`, payload);
  }

  deleteApiKeyById(id: string): Promise<void> {
    return this.client.delete(`${this.resource}/api-key`, id);
  }
}
