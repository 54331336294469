import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { mutations } from './mutations';
import { IAccountState } from './types';

const state: IAccountState = {
  selected_account: null,
  update_selected_account: null,
  update_billing_details: null,
  update_available_plan: null,
  update_api_key_list: null,
  update_selected_api_key: null
};

// eslint-disable-next-line import/prefer-default-export
export const account: Module<IAccountState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
