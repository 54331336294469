import { IUpdateAccountParams } from '@/modules/account/account.interface';
import { IAddApiKey, IApiKey, IApiKeyResponse } from '@/modules/api-key/api-key.interface';
import { IAvailablePlanApi, IBillingDetails } from '@/modules/billing/billing.interface';
import { IRootState } from '@/modules/common/common.interface';
import Api from '@/services/api/ApiFactory';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { IAccountApi } from '@connect-saas/types';
import { ActionContext, ActionTree } from 'vuex';
import { IAccountState } from './types';

const ApiFactory = new Api();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<IAccountState, IRootState> = {
  GET_ACCOUNT_INFORMATION({ commit, dispatch }: ActionContext<IAccountState, IRootState>) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAccount()
        .getAccount()
        .then((response: ResourceResponse<IAccountApi | null>) => {
          commit('SELECTED_ACCOUNT', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  UPDATE_ACCOUNT_INFORMATION(
    { commit, dispatch }: ActionContext<IAccountState, IRootState>,
    payload: IUpdateAccountParams
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAccount()
        .updateAccount(payload)
        .then((response: ResourceResponse<IAccountApi>) => {
          commit('UPDATE_SELECTED_ACCOUNT', response);
          dispatch('GET_ACCOUNT_INFORMATION');
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  DELETE_ACCOUNT({ dispatch }: ActionContext<IAccountState, IRootState>) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAccount()
        .deleteAccount()
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_BILLING_DETAILS({ commit, dispatch }: ActionContext<IAccountState, IRootState>) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAccount()
        .getBillingDetails()
        .then((response: ResourceResponse<IBillingDetails>) => {
          commit('UPDATE_BILLING_DETAILS', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_AVAILABLE_PLAN({ commit, dispatch }: ActionContext<IAccountState, IRootState>) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAccount()
        .getAvailablePlan()
        .then((response: ResourceResponse<IAvailablePlanApi>) => {
          commit('UPDATE_AVAILABLE_PLAN', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_API_KEYS({ commit, dispatch }: ActionContext<IAccountState, IRootState>) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAccount()
        .getApiKey()
        .then((response: IApiKeyResponse) => {
          commit('UPDATE_API_KEY_LIST', response.data);
          resolve(response.data);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  CREATE_API_KEY(
    { commit, dispatch }: ActionContext<IAccountState, IRootState>,
    payload: IAddApiKey
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAccount()
        .createApiKey(payload)
        .then((response: ResourceResponse<IApiKey>) => {
          commit('UPDATE_SELECTED_API_KEY', response.resource);
          dispatch('GET_API_KEYS');
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  DELETE_SELECTED_API_KEY({ dispatch }: ActionContext<IAccountState, IRootState>, id: string) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAccount()
        .deleteApiKeyById(id)
        .then((response) => {
          dispatch('GET_API_KEYS');
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  }
};
