import { IApiKey } from '@/modules/api-key/api-key.interface';
import { IAvailablePlanApi, IBillingDetails } from '@/modules/billing/billing.interface';
import { IAccountApi } from '@connect-saas/types';
import { MutationTree } from 'vuex';
import { IAccountState } from './types';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<IAccountState> = {
  SELECTED_ACCOUNT(state: IAccountState, selected_account: IAccountApi) {
    state.selected_account = selected_account;
  },
  UPDATE_SELECTED_ACCOUNT(state: IAccountState, update_selected_account: IAccountApi) {
    state.update_selected_account = update_selected_account;
  },
  UPDATE_BILLING_DETAILS(state: IAccountState, update_billing_details: IBillingDetails) {
    state.update_billing_details = update_billing_details;
  },
  UPDATE_AVAILABLE_PLAN(state: IAccountState, update_available_plan: IAvailablePlanApi) {
    state.update_available_plan = update_available_plan;
  },
  UPDATE_API_KEY_LIST(state: IAccountState, update_api_key_list: IApiKey[]) {
    state.update_api_key_list = update_api_key_list;
  },
  UPDATE_SELECTED_API_KEY(state: IAccountState, update_selected_api_key: IApiKey) {
    state.update_selected_api_key = update_selected_api_key;
  }
};
